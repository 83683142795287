<template>
  <div
    v-show="showOrder"
    class="c-cart-order-unpayment-card"
  >
    <div class="card-header">
      <span
        class="card-header-title"
        tabindex="0"
      >
        {{ language.SHEIN_KEY_PWA_15216 }}
      </span>
      <span
        v-if="showCountDownShow"
        class="card-header-countdown"
      >
        <Icon
          name="sui_icon_hourglass_12px_2"
          size="16px"
          color="#FA6338"
        />
        {{ timeh }} : {{ timem }} : {{ times }}
      </span>
    </div>
      
    <div
      v-enterkey
      class="card-box"
      tabindex="0"
      :aria-label="language.SHEIN_KEY_PWA_17171"
      :data-order_id="order.billno"
      da-event-click="1-8-7-16"
      @click="viewOrder"
    >
      <div
        v-for="(item,index) in orderGoodsList"
        :key="index"
        class="order-item"
      >
        <template v-if="item && item.product">
          <img
            :src="getMainImageUrl(item, IS_RW)"
            alt=""
          />
          <div
            v-if="item.showMoreModal"
            class="more-modal"
          >
            <i 
              class="icon-fiftermore_" 
              :class="[oldIconPrefix]"
            ></i>
          </div>
        </template>
      </div>
      <div class="order-item view-order">
        <div class="view-order-box">
          <i 
            class="icon-liebiaoviewmore" 
            :class="[oldIconPrefix]" 
          ></i>
          <span>{{ language.SHEIN_KEY_PWA_17171 }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { daEventCenter } from '../../../services/eventCenter'
import { getMainImageUrl } from 'public/src/pages/cartNew/utils/index.js'
const { IS_RW } = gbCommonInfo
import { markPoint } from 'public/src/services/mark/index.js'
import { Icon } from '@shein-aidc/icon-vue2'

export default {
  components: {
    Icon
  },
  data: function () {
    return {
      // showOrder: false,
      // order: {},

      // 倒计时
      timeh: '',
      timem: '',
      times: '',
      timeCountDown: '',
      showCountDownShow: false,

      fetching: false,
      IS_RW
    }
  },
  computed: {
    ...mapState('cart', ['language', 'loginStatus', 'unPaidOrderInfo']),
    ...mapGetters('cart', ['oldIconPrefix']),
    orderGoodsList () {
      let result = []

      const list = this.order.order_goods_list || []
      const length = list && list.length || 0

      for(const goods of list) {
        if(goods?.is_prime_goods || goods?.is_xtra_goods) continue
        result.push(goods)
        if(result.length >= 4) break
      }
      let imgsCount = result.length
      if(imgsCount < 4) {
        result = result.concat(Array(4 - imgsCount).fill({}))
      }
      if (length > 4) result[3].showMoreModal = true
      return result
    },
    showOrder() {
      return this.unPaidOrderInfo?.orderData?.billno
    },
    order() {
      return this.unPaidOrderInfo.orderData
    }
  },
  watch: {
    'unPaidOrderInfo': {
      deep: true,
      handler () {
        // !this.showOrder && this.handleGetOrder()
      }
    },
  },
  mounted() {
    let timer = setInterval(() => {
      if(!this.timeCountDown) {
        this.handleGetOrder()
      } else {
        clearInterval(timer)
      }
    }, 1000)
  },
  methods: {
    viewOrder () {
      if (!this.order.billno) return
      // this.$routerPush(`${langPath}/user/orders/detail/${this.order.billno}`)
      markPoint('toNextPageClick', 'public')
      this.$routerPush({
        name: 'UserOrdersList',
        params: { tab: 'Unpaid' }
      })
    },
    setCountDown () {
      const countDownData = {
        'endTime': parseInt(this.order.expire_countdown)
      }

      if ((Date.parse(new Date()) / 1000) < countDownData.endTime) {
        this.showCountDownShow = true
        this.orderCountDown(countDownData, Date.parse(new Date()) / 1000)
        this.timeCountDown = setInterval(() => {
          this.orderCountDown(countDownData, Date.parse(new Date()) / 1000)
        }, 1000)
      }
    },
    orderCountDown (obj, selfTime) {
      function toDouble (num) {
        if (num < 10) {
          return '0' + num
        } else {
          return num
        }
      }
      if (selfTime < obj.endTime) {
        var intervalTime = obj.endTime - selfTime
        var houst = Math.floor(intervalTime / 3600)
        intervalTime = Math.floor(intervalTime % 3600)
        var minutes = Math.floor(intervalTime / 60)
        var seconds = Math.floor(intervalTime % 60)
        this.timeh = toDouble(houst)
        this.timem = toDouble(minutes)
        this.times = toDouble(seconds)
      } else {
        clearInterval(this.timeCountDown)
        this.times = '00'
      }
    },
    handleGetOrder() {
      if ( this.unPaidOrderInfo.orderData?.billno ) {
        if ( typeof window == 'undefined' ) return
        this.setCountDown()
        daEventCenter.triggerNotice({
          daId: '1-8-7-15',
          extraData: {
            order_id: this.order.billno
          }
        })
      }
    },
    getMainImageUrl,
  }
    
}
</script>
<style lang="less" scoped>
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.c-cart-order-unpayment-card {
  min-height: 4.08rem;
  background: #fff;
  margin-bottom: 0.2667rem;
  padding: 0 .32rem .64rem .32rem;
  .card-header {
    padding: .106rem 0;
    .card-header-title{
      font-weight: bold;
      font-size: 12px;
    }
    .card-header-countdown{
      color: #D53333;
      .fr();
      .font-dpr(20px);
      font-weight: bold;
      i {
        font-size: 16px;
        font-weight: normal;
        .margin-r(.06rem);
      }
    }
  }
  .card-box{
    min-height: 2.6667rem;
    padding: .21rem;
    background-color: #f6f6f6;
    display: flex;
    width: 100%;
    .order-item{
      width: 20%;
      height: 168/75rem;
      margin: 0 .06rem;
      position: relative;
      img{
        width: 100%;
        height: 100%;
      }
      .more-modal{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,.6);
        text-align: center;
        i {
          .font-dpr(48px);
          color: #fff;
          position: absolute;
          .left(0);
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
        }
      }
      &.view-order {
        overflow: hidden;
        text-align: center;
        .view-order-box{
          display: inline-block;
          position: absolute;
          width: 100%;
          top: 50%;
          .padding-l(.1rem);
          .left(0);
          transform: translateY(-50%);
          .icon-liebiaoviewmore{
            display: inline-flex;
            width: 56/75rem;
            height: 56/75rem;
            .font-dpr(56px);
          }
          span{
            display: block;
            // margin-top: -.1rem;
            line-height: 1;
            font-weight: bold;
            .font-dpr(20px);
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
</style>
